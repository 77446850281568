import React, { useState } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import ProductCard from "./cardProduct"
import SaleValue from "./saleValue"
import Countdown from "react-countdown"
import djangoRequests from "../../utils/djangoRequests"

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.secondary,
    borderRadius: 20,
    width: "fit-content",
    height: "fit-content",
    padding: 40,
    "@media (max-width: 1025px)": {
      padding: 12,
    },
    "@media (max-width: 767px)": {
      padding: 28,
      width: "100%",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 28,
  },
  h3: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.color.accentSecondary,
  },
  h6: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: "100%",
    color: theme.palette.color.main,
    backgroundColor: theme.palette.background.brandLight,
    padding: "8px 12px 8px 12px",
    borderRadius: 100,
    marginTop: 10,
    width: "fit-content",
  },
}))

/**
 * Блок товар дня
 * @module src/components/mainPage/dayProduct
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученый из prismic
 */
export default function DayProduct(props) {
  const classes = useStyles()
  const product =
    props.data.allPrismicDayProduct.edges[0]?.node.data.product?.document?.data
  const productUID =
    props.data.allPrismicDayProduct.edges[0]?.node.data.product?.document?.uid
  const [price, setPrice] = useState(product?.price)

  //useEffect to get price of product
  React.useEffect(() => {
    djangoRequests.getPrice([productUID]).then(res => {
      if (res.products[productUID] === false) return
      setPrice(res.products[productUID])
    })
  }, [])

  const Tomorrow = new Date(
    new Date().getTime() + 24 * 60 * 60 * 1000
  ).setHours(0, 0, 0, 0)

  const Count = () => (
    <Countdown
      date={Tomorrow}
      zeroPadTime={2}
      zeroPadDays={2}
      renderer={renderer}
    />
  )

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Count />
    } else {
      const hour = hours < 10 ? `0${hours}` : hours
      const min = minutes < 10 ? `0${minutes}` : minutes
      const sec = seconds < 10 ? `0${seconds}` : seconds
      return (
        <span>
          {hour}:{min}:{sec}
        </span>
      )
    }
  }

  const old_price =
    props.data.allPrismicDayProduct.edges[0]?.node.data.product?.document?.data
      .old_price

  const sale = Math.trunc((1 - price / old_price) * 100)

  return (
    <div className={`${classes.root} index--day--product`}>
      <div className={classes.title}>
        <div
          className={classes.title_count}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Typography variant="h3" component="span" className={classes.h3}>
            Товар дня
          </Typography>
          <span
            style={{
              color: "#131313",
              width: "fit-content",
              padding: "8px 12px 8px 12px",
              fontSize: 12,
              marginTop: 10,
              fontWeight: 700,
              lineHeight: "100%",
              borderRadius: 100,
              backgroundColor: "#D2D0E9",
            }}
          >
            <Count />
          </span>
          {/* <Typography variant="h6" component="h3" className={classes.h6}>
            <Count />
          </Typography> */}
        </div>
        <SaleValue value={sale} />
      </div>
      <ProductCard
        product={
          props.data.allPrismicDayProduct.edges[0]?.node.data.product?.document
        }
        price={price}
      />
    </div>
  )
}
